<template>
    <div class="container-fluid p-4">
        <div class="row row-cols-1 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Basic Date Picker</div>
                    <div class="card-body">
                        <b-calendar
                            v-model="value"
                            nav-button-variant="primary"
                            selected-variant="primary"
                            today-variant="primary"
                            weekday-header-format="narrow"
                        >
                        </b-calendar>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Basic Date Picker</div>
                    <div class="card-body">
                        <b-calendar v-model="value" locale="en-US" weekday-header-format="narrow"></b-calendar>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Basic Date Picker / Disabled dates</div>
                    <div class="card-body">
                        <b-calendar v-model="value" :date-disabled-fn="dateDisabled" locale="en"
                                    weekday-header-format="narrow"></b-calendar>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Full Width</div>
                    <div class="card-body">
                        <b-calendar block class="w-75" locale="en-US" weekday-header-format="narrow"></b-calendar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Calendar',
    data() {
        return {
            value: null,
            state: 'disabled'
        }
    },
    computed: {
        disabled() {
            return this.state === 'disabled'
        },
        readonly() {
            return this.state === 'readonly'
        },
        constraint() {
            const now = new Date()
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            // Limit to 2 weeks
            const maxDate = new Date(today)
            maxDate.setDate(maxDate.getDate() + 14);

            return {
                min: today,
                max: maxDate
            }
        }
    },
    methods: {
        dateDisabled(ymd, date) {
            // Disable weekends (Sunday = `0`, Saturday = `6`) and
            // disable days that fall on the 13th of the month
            const weekday = date.getDay()
            const day = date.getDate()
            // Return `true` if the date should be disabled
            return weekday === 0 || weekday === 6 || day === 13
        },
        setToday() {
            const now = new Date()
            this.value = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        },
        clearDate() {
            this.value = ''
        },
        dateClass(ymd, date) {
            const day = date.getDate()
            return day >= 10 && day <= 20 ? 'table-info' : ''
        }
    },
    mounted() {
        this.setToday();
    }
}
</script>
